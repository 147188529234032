import React from "react";
import { saveAs } from "file-saver";

const JSONFileCreator = () => {
  const createJSONFile = () => {
    const jsonData = JSON.parse(localStorage.getItem("templates"));

    const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
      type: "application/json;charset=utf-8",
    });
    saveAs(blob, "meineDatei.json");
  };

  return (
    <button className="downUploadButton" onClick={createJSONFile}>
      Template Downloaden
    </button>
  );
};

export default JSONFileCreator;
