import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "../AddNewEntry.css"; // Importiere deine eigene CSS-Datei

const AddNewEntry = ({ template, setTemplate, setShowenElement }) => {
  const [name, setName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [textPerDu, setTextPerDu] = useState("");
  const [textPerSie, setTextPerSie] = useState("");
  const [placeholderArr, setPlaceholderArr] = useState([]);

  const perDuChangeHandler = (text) => {
    let placeholders = text.match(/%%(.*?)%%/g);
    setPlaceholderArr(placeholders);
    setTextPerDu(text);
  };

  const perSieChangeHandler = (text) => {
    setTextPerSie(text);
  };

  const addEntry = () => {
    const objectToSave = {
      textPerDu,
      textPerSie,
      keywords,
      placeholders: placeholderArr,
      deleted: false,
    };

    const objectToSaveInLocal = { ...template, [uuidv4()]: objectToSave };

    localStorage.setItem("templates", JSON.stringify(objectToSaveInLocal));

    setTemplate(objectToSaveInLocal);

    setShowenElement("templates");
  };

  return (
    <div className="add-new-entry-container">
      <h2>Neues Template erstellen</h2>
      <input
        className="keywords-input"
        placeholder="Keywords - test, test1, test2"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
      />
      <textarea
        id="perDu"
        className="text-area"
        placeholder="Text per Du"
        value={textPerDu}
        onChange={(e) => perDuChangeHandler(e.target.value)}
      ></textarea>
      <textarea
        id="perSie"
        className="text-area"
        placeholder="Text per Sie"
        value={textPerSie}
        onChange={(e) => perSieChangeHandler(e.target.value)}
      ></textarea>
      <div className="button-container">
        <button className="save-button" onClick={addEntry}>
          Hinzufügen
        </button>
        <button
          className="grey-button"
          onClick={() => setShowenElement("templates")}
        >
          Abbrechen
        </button>
      </div>
    </div>
  );
};

export default AddNewEntry;
