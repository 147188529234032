import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function MyDropzone({ setTemplate }) {
  const onDrop = useCallback((acceptedFiles) => {
    const fileToRead = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event.target.result;

      if (fileContent) {
        localStorage.setItem(
          "templates",
          JSON.stringify(JSON.parse(fileContent))
        );
        setTemplate(JSON.parse(fileContent));
      }
    };

    reader.readAsText(fileToRead);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <span {...getRootProps()}>
      <input {...getInputProps()} />
      <button className="downUploadButton">Template laden</button>
    </span>
  );
}

export default MyDropzone;
