import React, { useState, useEffect } from "react";
import "../templateListAndSearch.css"; // Stil-Datei für das Design

const ColoredText = ({ text, placeholders, onClick }) => {
  const parts = text.split(/(%%.*?%%)/g);

  return (
    <p onClick={onClick} className="clickable-text">
      {parts.map((part, index) => {
        if (part.startsWith("%%") && part.endsWith("%%")) {
          const placeholderKey = part.slice(2, -2);
          const placeholderValue =
            placeholders[placeholderKey] || placeholderKey;
          return (
            <span key={index} className="colored-placeholder">
              {placeholderValue}
            </span>
          );
        }
        return <span key={index}>{part}</span>;
      })}
    </p>
  );
};

const TemplateListAndSearch = ({ template, setShowenElement, setIdToEdit }) => {
  const [inputValues, setInputValues] = useState({});
  const [searchKeywords, setSearchKeywords] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Alt" && !event.ctrlKey) {
      event.preventDefault();
      document.getElementById("search-input").focus();
    } else if (event.ctrlKey && event.altKey) {
      event.preventDefault();
      setSearchKeywords("");
      setInputValues({});
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleInputChange = (placeholder, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [placeholder]: value,
    }));
  };

  const handleSearchInputChange = (event) => {
    setSearchKeywords(event.target.value.toLowerCase());
  };

  const filteredTemplates = Object.entries(template).filter(([key, item]) => {
    if (item.deleted === true) {
      return false;
    }
    if (!searchKeywords) return true;
    const keywords = searchKeywords.split(" ");
    return keywords.every((keyword) =>
      item.keywords.toLowerCase().includes(keyword.trim())
    );
  });

  return (
    <div className="template-list-container">
      <div style={{ display: "flex" }}>
        <input
          type="text"
          placeholder="Suchen nach Keywords (durch Leerzeichen getrennt)"
          value={searchKeywords}
          onChange={handleSearchInputChange}
          className="search-input"
          id="search-input"
        />
        <button className="clear-button" onClick={() => setSearchKeywords("")}>
          Leeren
        </button>
      </div>
      {filteredTemplates.map(([key, value]) => (
        <div key={key} className="template-card">
          <div className="template-card-content">
            <div className="keywords-list">
              <p>Keywords: {value.keywords?.replace(/,/g, " - ")}</p>
            </div>
            <div className="template-placeholders">
              {value.placeholders?.map((elem) => (
                <input
                  key={elem}
                  placeholder={elem.slice(2, -2)}
                  value={inputValues[elem.slice(2, -2)] || ""}
                  onChange={(e) =>
                    handleInputChange(elem.slice(2, -2), e.target.value)
                  }
                  className="input-field"
                />
              ))}
            </div>
            <div className="text-columns">
              <div
                className="text-column"
                onClick={() =>
                  navigator.clipboard.writeText(
                    value.textPerDu.replace(
                      /%%(.*?)%%/g,
                      (_, placeholder) =>
                        inputValues[placeholder] || placeholder
                    )
                  )
                }
              >
                <div className="column-label">Du</div>
                <ColoredText
                  text={value.textPerDu}
                  placeholders={inputValues}
                />
              </div>
              <div
                className="text-column"
                onClick={() =>
                  navigator.clipboard.writeText(
                    value.textPerSie.replace(
                      /%%(.*?)%%/g,
                      (_, placeholder) =>
                        inputValues[placeholder] || placeholder
                    )
                  )
                }
              >
                <div className="column-label">Sie</div>
                <ColoredText
                  text={value.textPerSie}
                  placeholders={inputValues}
                />
              </div>
            </div>
          </div>
          <button
            className="edit-button"
            onClick={() => {
              console.log(key, value);
              setIdToEdit(key);
              setShowenElement("edit");
            }}
          >
            Editieren
          </button>
        </div>
      ))}
    </div>
  );
};

export default TemplateListAndSearch;
