import React, { useState, useEffect } from "react";

const EditEntry = ({ idToEdit, template, setTemplate, setShowenElement }) => {
  console.log(setShowenElement);
  const [name, setName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [textPerDu, setTextPerDu] = useState("");
  const [textPerSie, setTextPerSie] = useState("");
  const [placeholderArr, setPlaceholderArr] = useState([]);

  useEffect(() => {
    setName(template[idToEdit].name);
    setKeywords(template[idToEdit].keywords);
    setTextPerDu(template[idToEdit].textPerDu);
    setTextPerSie(template[idToEdit].textPerSie);
    setPlaceholderArr(template[idToEdit].placeholders);
  }, []);

  const perDuChangeHandler = (text) => {
    let placeholders = text.match(/%%(.*?)%%/g);

    setPlaceholderArr(placeholders);

    setTextPerDu(text);
  };
  const perSieChangeHandler = (text) => {
    setTextPerSie(text);
  };

  const addEntry = () => {
    const objectToSave = {
      name,
      textPerDu,
      textPerSie,
      keywords,
      placeholders: placeholderArr,
    };

    const objectToSaveInLocal = { ...template, [idToEdit]: objectToSave };

    localStorage.setItem("templates", JSON.stringify(objectToSaveInLocal));

    setTemplate(objectToSaveInLocal);

    setShowenElement("templates");
  };

  const delEntry = () => {
    template[idToEdit].deleted = true;

    localStorage.setItem("templates", JSON.stringify(template));

    setShowenElement("templates");
  };

  return (
    <div className="add-new-entry-container">
      <button className="delete-button" onClick={delEntry}>
        Löschen
      </button>
      <h2>Edit Entry</h2>

      <input
        className="keywords-input"
        placeholder="Keywords - test, test1, test2"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
      />
      <textarea
        id="perDu"
        className="text-area"
        placeholder="Text per Du"
        value={textPerDu}
        onChange={(e) => perDuChangeHandler(e.target.value)}
      ></textarea>
      <textarea
        id="perSie"
        className="text-area"
        placeholder="Text per Sie"
        value={textPerSie}
        onChange={(e) => perSieChangeHandler(e.target.value)}
      ></textarea>
      <div className="button-container">
        <button className="save-button" onClick={addEntry}>
          Speichern
        </button>
        <button
          className="grey-button"
          onClick={() => setShowenElement("templates")}
        >
          Abbrechen
        </button>
      </div>
    </div>
  );
};

export default EditEntry;
