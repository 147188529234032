import "./App.css";
import { useState, useEffect } from "react";
import UploadFile from "./components/UploadFile";
import DownloadFile from "./components/DownloadFile";
import AddNewEntry from "./components/AddNewEntry";
import TemplateListAndSearch from "./components/TemplateListAndSearch";
import EditEntry from "./components/EditEntry";

function App() {
  // localStorage.clear();
  const [showenElement, setShowenElement] = useState("templates");
  const [template, setTemplate] = useState({});
  const [idToEdit, setIdToEdit] = useState("");

  useEffect(() => {
    const templates = localStorage.getItem("templates");

    console.log(JSON.parse(templates));

    if (templates && templates.length !== 0) {
      setTemplate(JSON.parse(templates));
    }
  }, []);

  return (
    <div className="App">
      <div className="downUploadWrapper">
        <UploadFile setTemplate={setTemplate} />
        <DownloadFile />
      </div>
      {showenElement === "templates" ? (
        <button
          className="addTemplateButton"
          onClick={() => setShowenElement("add")}
        >
          Template hinzufügen
        </button>
      ) : null}
      {showenElement === "templates" ? (
        <TemplateListAndSearch
          template={template}
          setShowenElement={setShowenElement}
          setIdToEdit={setIdToEdit}
        />
      ) : showenElement === "add" ? (
        <AddNewEntry
          template={template}
          setTemplate={setTemplate}
          setShowenElement={setShowenElement}
        />
      ) : showenElement === "edit" ? (
        <EditEntry
          idToEdit={idToEdit}
          template={template}
          setTemplate={setTemplate}
          setShowenElement={setShowenElement}
        />
      ) : (
        <p>Something is wrong this should never show</p>
      )}
    </div>
  );
}

export default App;
